<template>
  <b-modal
    ref="observacaoModal"
    size="lg"
    no-close-on-backdrop
    :hide-header-close="true"
    footer-class="d-block"
  >
    <template #modal-title>
      <h3 style="word-break: break-all;">
        {{ value.title ? value.title : value.razaoSocial }}
      </h3>
    </template>
    <div>
      <b-form-group
        label="Observação"
      >
        <b-form-textarea
          v-model="value.observacao"
          style="height: 5rem;"
          placeholder="Adicione uma observação"
          @change="sendToParent"
        />
      </b-form-group>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-btn
          variant="none"
          size="sm"
          class="btn-outline-success btn-none"
          style="margin-left: 0.3rem;"
          @click="addObservacao()"
        >
          Salvar
        </b-btn>
        <b-btn
          variant="none"
          size="sm"
          class="btn-outline-danger btn-none"
          style="margin-left: 0.3rem;"
          @click="close()"
        >
          Fechar
        </b-btn>
      </div>
      <div
        class="overflow-auto mt-2 pr-1"
        style="max-height: 40rem;"
      >
        <div
          v-for="observacao in value.observacoes"
          :key="observacao.id"
        >
          <visualizar-observacao
            ref="VisualizarObservacao"
            :value="observacao"
            :editavel="canEdit(observacao.usuarioId)"
            :api="api"
            @update="updateObservacao($event)"
            @delete="removerObservacao($event)"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>

import { getUserData } from '@/auth/utils'

export default {
  name: 'ModalObservacao',
  components: {
    VisualizarObservacao: () => import('./VisualizarObservacao.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    idRegistro: {
      type: Number,
      default: 0,
    },
    tipo: {
      type: String,
      default: '',
    },
    api: {
      type: Object,
      required: true,
    },
    notificar: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // orderObservacoes(a, b) {
    //   if (a.last_nom < b.last_nom) {
    //     return -1
    //   }
    //   if (a.last_nom > b.last_nom) {
    //     return 1
    //   }
    //   return 0
    // },
    sendToParent(observacao) {
      this.$emit('change', observacao)
    },
    show() {
      const id = this.getIdDocumento()
      this.getObservacao(id).then(() => {
        this.$refs.observacaoModal.show()
      })
    },
    close(observacaoNova) {
      this.$refs.observacaoModal.hide()
      this.$emit('hidden', { hasObservacaoNova: observacaoNova, value: this.value })
    },
    canEdit(userId) {
      const userData = getUserData()
      if (userId === userData.usuarioId) {
        return true
      }
      return false
    },
    getObservacao(id) {
      return this.api.getObservacoes(id, this.tipo).then(payload => {
        this.$set(this.value, 'observacoes', payload.data)
      })
    },
    addObservacao() {
      if (this.value.observacao.length > 0) {
        const id = this.getIdDocumento()
        this.api.salvarObservacao({ idRegistro: id, tipo: this.tipo, observacao: this.value.observacao })
          .then(() => {
            this.value.observacao = ''
            this.close(true)
            this.notificar.sucesso('Observação salva com sucesso')
          })
          .catch(err => {
            this.close()
            this.notificar.erro(err.message || 'Não foi possível salvar a observação.')
            throw err
          })
      }
    },
    updateObservacao(evento) {
      this.api.salvarObservacao({ id: evento.id, observacao: evento.observacao })
        .then(() => {
          this.notificar.sucesso('Observação salva com sucesso')
          this.close(true)
        })
        .catch(err => {
          this.notificar.erro(err.message || 'Não foi possível salvar a observação.')
          throw err
        })
    },
    removerObservacao(value) {
      const msgErro = 'Não foi possível excluir a observação.'
      this.api.removerObservacao(value.id)
        .then(payload => {
          if (payload.data === true) {
            this.notificar.sucesso('Observação removida.')
          } else {
            this.notificar.erro(msgErro)
          }
          this.close()
        })
        .catch(err => {
          this.notificar.erro(err.message || msgErro)
        })
    },
    getIdDocumento() {
      return this.idRegistro === 0 ? this.value.id : this.idRegistro
    },
  },
}
</script>
